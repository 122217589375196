const addListener = function (el, type, fn) {
    el.addEventListener(type, fn, false)
}

//去掉空格
const spaceFilter = function (el) {
    addListener(el, 'input', () => {
        el.value = el.value.replace(/\s+/, '')
    })
}
// 失焦
const blurFilter = function (el) {
    addListener(el, 'blur', () => {
        // alert(el.value)
        console.log(el)
        el.value = String(el.value).replace(/\.$/,'')
        // el.value = Number(el.value)
    })
}

// 限制只能输入整数和小数（价格类、最多两位小数）
const priceFilter = function (el) {
    addListener(el, 'input', debounce(() => {//添加防抖 防止反复触发事件导致内存溢出
        el.value = (el.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
        if (isNaN(el.value)) {
            el.value = ''
        }
        //触发input事件
        el.dispatchEvent(new Event('input'))
    }))
}
// 防抖
let debounce = (fn, delay) => {
    var delay = delay || 100;
    var timer;
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, delay);
    };
}

// 输入框过滤器
const inputFilter =  {
    bind(el, binding) {
        if (el.tagName.toLowerCase() !== 'input') {
            el = el.getElementsByTagName('input')[0]
        }
        spaceFilter(el)
        blurFilter(el)
        switch (binding.arg) {
            case 'price':
                priceFilter(el)
                break
            default:
                console.warn('未知指令类型', binding.arg)
                break
        }
    }
}




const inputMoney = {
    bind(el, binding) {
        el.addEventListener('keydown', function (e) {
          e = e || window.event
          const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
          const re = /\d/
          alert(charcode)
          if (charcode === 46) {
            // el.children[0].value是配合了el-input输入框使用的，使用input输入框时直接用el.value
            // 只能输入一个小数点，超过一个则禁止输入
            if (el.value.includes('.')) {
              e.preventDefault()
            }
            return
          } else if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
            if (e.preventDefault) {
              e.preventDefault()
              // console.log(el.value)
            } else {
              e.returnValue = false
            }
          }else{
            // console.log(e.keyCode)
            // alert(e.keyCode)
            // if (el.value.includes('.')) {
            //   let f = el.value.split('.')[1]
            //   if(String(f).length>=2){
            //     e.returnValue = false
            //   }
            // }
          }
        })
        el.addEventListener('blur', function (e) {
          // console.log(e)
        })
    }
}

export default {
    inputFilter,
    inputMoney
}