<template>
  <div id="app">
    <div class="app-container">
      <!-- <navigation>
      <router-view></router-view>
      </navigation> -->
    
      <router-view></router-view>
    </div>
  </div>
</template>


<script>
export default {
  name: 'app',
  data(){
    return {
      
    }
  },
  created(){
    
  },
  mounted(){

  },
  watch:{
    $route(to,from){ 
      document.title = to.meta.title
    }
  },
}
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  .app-container{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: 0.28rem;
    background-color: #fff;
  }
  .pt{
    padding-top: 0.88rem;
  }
}
.ellipsis-1{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fwb{
  font-weight: bold;
}
input{
  -webkit-tap-highlight-color:transparent;
  -webkit-appearance: none;
}
em{
  font-style: normal;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
