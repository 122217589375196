import axios from 'axios'
import { Toast, Dialog } from 'vant';
//授权
import { silentLogin, isLogin } from '../api/pay'

import store from '@/store'
// 创建axios实例
// axios.defaults.baseURL = "http://api-np-dev.hzhhkeji.com/" // api 的 base_url
axios.defaults.baseURL = process.env.VUE_APP_BASE_API // api 的 base_url
axios.defaults.timeout = 35000 // 请求超时时间
axios.defaults.withCredentials = true;
// request拦截器
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    config.headers["Content-Type"] = 'application/x-www-form-urlencoded;charset=UTF-8';
    // if (store.getters.token) {
    //   // 让每个请求携带 token
    //   // ['X-Token']是一个自定义头信息键
    //   // 请根据实际情况修改一下
    //config.headers['X-Token'] = "pqCEtd_q4bs51KnUblaAq5XLKTiE7rdf_1637771627"
    // config.params['access_token'] = "pqCEtd_q4bs51KnUblaAq5XLKTiE7rdf_1637771627"
    // }
    return config
  },
  error => {
    // 请求出现错误
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// respone拦截器
axios.interceptors.response.use(
  /**
  * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
  * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
  */
  response => {
    const res = response.data
    //没有token的情况下
    if (res.resultCode == "401") {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //当前环境是微信的环境
        let codestr = decodeURIComponent((new RegExp('[?|&]' + codestr + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
        if (codestr) {
          silentLogin({ code: code }).then((res) => {
            if (res.resultCode == 1)
              localStorage.setItem("access-token", res.resultInfo.access_token)
          })
        }

      }
    }
    //原来逻辑
    if (res.resultCode != 1) {
      Toast(res.message);
      return Promise.reject(new Error(res.message || '错误'))
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    Toast.fail("请求错误");
    return Promise.reject(error)
  }
)
const service = ({
  url,
  method,
  data,
  isUplaod
} = {}) => {
  var params = new URLSearchParams()
  if (isUplaod) {
    params = data
  } else {
    for (const i in data) {
      params.append(i, data[i])
    }
  }
  return new Promise((resolve, reject) => {
    const fromdata = {
      url,
      method: method || 'post'
    }
    if (method.toLowerCase() === 'get') {
      fromdata.params = params
    } else {
      fromdata.data = params
    }
    axios(fromdata).then((res) => {
      resolve(res)
    }).catch((err) => {
      resolve(err)
    })
  })
}
export default service
