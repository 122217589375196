import request from '@/utils/request'
/**
 * 获得审核通过的供应商
 */


// window.localStorage.setItem("access-token",'hkvvVsmGif0C-zhpaeq6WvzhdLlgDSH4_1666858184')

export const pay_getMerchantInfo = (data) => {
    return request({
        url: `/pay/open/get-merchant-info`,
        method: 'get',
        data,
    })
}
// 支付
export const pay_submit = (data) => {
    return request({
         url: `/pay/icbc?access-token=${window.localStorage.getItem("access-token")}`,
        //url: `/pay/icbc?access-token=${access_token}`,
        method: 'get',
        data,
    })
}




// export const pay_test_submit = (data) => {
//     return request({
//         url: `/pay/icbc?access-token=${window.localStorage.getItem("access-token")}`,
//         //  url: `/pay/icbc?access-token=${access_token}`,
//         method: 'get',
//         data,
//     })
// }



//对换优惠券列表
export const getdiscountcouponlist = (data) => {
    return request({
         //url: `/customer/tickets/my-record?access-token=${access_token}`,
        url: `/customer/authorize/my-record?access-token=${window.localStorage.getItem("access-token")}`,
        method: 'post',
        data,
    })
}


//授权页面的操作
/**
 * 客户是否正常登陆
 */
export const isLogin = (data) => {
    let access_token = window.localStorage.getItem("access-token");
    let url = "customer/authorize/is-guest";
    if (access_token) {
        url = url + "?access-token=" + access_token
    }
    return request({
        url: url,
        method: 'GET',
        data
    })
}
/**
 * 判断是否登录并且返回支付宝的授权页面
 * @param  data 
 * @returns 
 */
export const isAlipayLogin = (data) => {
    return request({
        url: `customer/authorize/is-guest-alipay`,
        method: 'GET',
        data
    })
}


/**
 * 微信静默授权
 */
export const silentLogin = (data) => {
    return request({
        url: `/customer/authorize/callback`,
        method: 'GET',
        data
    })
}

// 支付宝的静默授权
export const silentLogin_alipay = (data) => {
    return request({
        url: `/customer/authorize/callback-alipay-auth`,
        method: 'GET',
        data
    })
}





//支付完成
export const getpaysuccesss = (data) => {
    return request({
        url: `/pay/icbc/get-order-info`,
        method: 'GET',
        data
    })
}



//支付完成
export const getpaysuccesssTradeOut = (data) => {
    return request({
        url: `pay/icbc/get-out-trade-no`,
        method: 'GET',
        data
    })
}


export const getuniqid = (data) => {
    return request({
        url: `/pay/open/get-uniqid`,
        method: 'post',
        data
    })
}

//var access_token = "DVq4rbJVgBe-COw0NelNknVIfjuuDEyX_1644979772"

//获取当前市场的信息
export const getmarketlist = (data) => {
    return request({
        url: `/customer/default/get-coupons?access-token=${window.localStorage.getItem("access-token")}`,
        //url: `/customer/default/get-coupons?access-token=${access_token}`,
        method: 'get',
        data
    })
}

//跳转银行
export const getbank = (data) => {
    return request({
        url: `/customer/coupon/get-qr-generate-url?access-token=${window.localStorage.getItem("access-token")}`,
        //url: `/customer/coupon/get-qr-generate-url?access-token=${access_token}`,
        method: 'get',
        data
    })
}


//请求支付
export const requestPay = (data) => {
    return request({
        url: `/customer/authorize/pay?access-token=${window.localStorage.getItem("access-token")}`,
        method: 'get',
        data
    })
}

export const requestXYPay = (data) => {
    return request({
        url: `/customer/authorize/xywft-pay?access-token=${window.localStorage.getItem("access-token")}`,
        method: 'get',
        data
    })
}
//交行的测试链接
export const requestCommPay = (data) => {
    return request({
        url: `/customer/authorize/comm-pay?access-token=${window.localStorage.getItem("access-token")}`,
        method: 'get',
        data
    })
}

export const getCustomerScore = (data) => {
    return request({
        url: `/customer/authorize/get-user-score-info?access-token=${window.localStorage.getItem("access-token")}`,
        method: 'get',
        data
    })
}

export const getquery_can_get_coupon = (data) => {
    return request({
        url: `/customer/authorize/query-can-get-coupon?access-token=${window.localStorage.getItem("access-token")}`,
        method: 'get',
        data
    })
}

export const weixinJSconfig = (data) => {
    return request({
        url: `/customer/authorize/weixin-jsconfig`,
        method: 'get',
        data
    })
}
export const baseUri = process.env.VUE_APP_BASE_API 