import Vue from 'vue'
import VueRouter from 'vue-router'
//本是调试代码  
//localStorage.setItem("access-token", 'hkvvVsmGif0C-zhpaeq6WvzhdLlgDSH4_1666858184')  //章哥 有积分
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//授权
import { silentLogin, isLogin, isAlipayLogin,silentLogin_alipay } from '../api/pay'
Vue.use(VueRouter)
/**
 * 
 * hidden  //是否需要隐藏标题栏 true 隐藏 
 * hiddenBack  //是否需要隐藏返回按钮 true 隐藏 
 */
const routes = [
  {
    path: '/',
    name: 'i',
    redirect: "/pay"
  },
  //优惠卷
  {
    path: '/couponslist',
    name: 'couponslist',
    component: () => import('../views/coupons_list.vue'),
    meta: {
      title: '代金券兑换'
    }
  },

  // 订单列表
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay.vue'),
    meta: {
      title: '付款'
    }
  },
  {
    path: '/xypay',
    name: 'xypay',
    component: () => import('../views/xypay.vue'),
    meta: {
      title: '付款'
    }
  },
  {
    path: '/comm',
    name: 'comm',
    component: () => import('../views/comm.vue'),
    meta: {
      title: '付款'
    }
  },
  {
    path: '/completion_payment',
    name: 'completion_payment',
    component: () => import('../views/completion_payment.vue'),
    meta: {
      title: '支付完成'
    }
  },
  {
    path: '/finish_payment',
    name: 'finish_payment',
    component: () => import('../views/finish_payment.vue'),
    meta: {
      title: '支付完成'
    }
  },
//打开小程序
{
  path: '/mini_program',
  name: 'mini_program',
  component: () => import('../views/mini_program.vue'),
  meta: {
    title: '打开小程序'
  }
},
]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
var _times = 0
router.beforeEach((to, from, next) => {
  if (is_wechat()) {
    var code = getUrlKey('code')
    if (code) {
      let access_token=localStorage.getItem("access-token");
      if (_times++ == 0) {
        silentLogin({ code: code,"access-token":access_token}).then((res) => {
          if (res.resultCode == 1){
            localStorage.setItem("access-token", res.resultInfo.access_token)
          }
        })
        next()
      } else {
        next()
      }
    } else {
      if (_times++ == 0) {
        isLogin({ url: window.location.href}).then((res) => {
          if (res.resultCode == 1) {
            if (res.resultInfo.isLogin == 0) {
               window.location.href = res.resultInfo.redirectUrl.headers.location[0]
            }else{
              next()
            }
          }else{
            next()
          }
        })
      } else {
        next()
      }
    }
  } else if(is_alipay()){
    var code = getUrlKey('auth_code')
    if (code) {
      if (_times++ == 0) {
        silentLogin_alipay({ code: code }).then((res) => {
          if (res.resultCode == 1)
            localStorage.setItem("access-token", res.resultInfo.access_token)
          next()
        })
      } else {
        next()
      }
    } else {
      if (_times++ == 0) {
        // next();
        isAlipayLogin({ url: window.location.href }).then((res) => {
          if (res.resultCode == 1) {
            if (res.resultInfo.isLogin == 0) {
                window.location.href = res.resultInfo.redirectUrl
            } else {
              next()
            }
          }
        })
      } else {
        next()
      }
    }
  }
  else {
    next()
  }
})
//授权
function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}


//首先判断是不是微信环境
function is_wechat() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
//判断浏览器是否是支付宝
function is_alipay() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/Alipay/i) == 'alipay') {
    return true
  } else {
    return false
  }
  // return true;
}