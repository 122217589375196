import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
// 页面缓存
import Navigation from 'vue-navigation'
Vue.use(Navigation, { router, store })

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

// 注册过滤器
import directives from '@/utils/directives.js'
for (const key in directives) {
  if (directives.hasOwnProperty(key)) {
    const item = directives[key];
    Vue.directive(key, item)
  }
}
Vue.prototype.$http = axios;
Vue.config.ignoredElements = ['wx-open-launch-weapp']

import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';
NutUI.install(Vue);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
